<template>
  <div>
    <div :class="'mobileNavBar ' + deviceType">
      <div class="appBar-header">
        <div @click="routeBack('/panel/siparisler',false)" class="header-route-back">
        <span v-if="deviceType === 'ios'">
          <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
          <span v-else>
          <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
        </div>
        <div class="header-title-container">
          <p class="header-title">
            Sipariş Detayı
          </p>
        </div>
      </div>
    </div>
    <div style="padding-top: 56px">
      <loader v-if="!pageLoaded"></loader>
      <div v-else>
        <p class="page-header" style="text-align:center; font-size: 16px; margin: 0; margin-bottom: 10px;padding: 10px" >
          <span @click="switchTab('orderDetail')" :class="{order_header_tab: true, active: !resolutionSection}">Sipariş Detayları</span>
          <span @click="switchTab('resolution')" :class="{order_header_tab: true, active: resolutionSection }" style="margin-left: 30px;">Çözüm Merkezi</span>
        </p>
        <div style=" padding: 10px;border: solid 1px #eaedf2; background-color: white; min-height: 500px; padding-bottom: 100px; ">
          <div v-if="!resolutionSection">
            <div style="border: solid 1px #eaedf2; padding: 10px 20px;text-align: left; ">
              <div style="display: inline-block;">
                <img style="width: 125px;" :src="imageURL"/>
              </div>
              <div style="display: inline-block; vertical-align: top; padding-top: 20px; margin-left: 20px; color: #2d3640; font-size: 20px;">
                <router-link :to="`${slugURL}`" class="order_detail_title">
                  {{ title }}
                </router-link>
              </div>

              <div style="display: inline-block;vertical-align: top; padding-top: 20px; margin-left: 20px; font-size: 14px;">
                {{ receivedUser.titleText }}
                <p style="padding-top:5px; font-size: 16px;">
                  <router-link :to="'/'+receivedUser.username" class="order_detail_username">{{ receivedUser.username }}</router-link>
                </p>
              </div>

              <div style="display: inline-block;vertical-align: top; padding-top: 20px; margin-left: 50px; font-size: 14px;">
                Ödenme Tarihi
                <p style=" font-size: 16px;padding-top:5px; color: #2d3640;">{{ payedAt | customDateFormat('dd MMM yyyy') }}</p>
              </div>
            </div>

            <table class="ui padded table desktoponl" style="border: none!important; border-radius: 0!important;">
              <thead>
              <tr>
                <th class="theorderseedetyth"></th>
                <th class="theorderseedetyth2">Adet</th>
                <th class="theorderseedetyth2">Süre</th>
                <th class="theorderseedetyth2">Tutar</th>
              </tr>
              </thead>
              <tbody>


              <tr v-if="!order.package">
                <td class="truncated" style="font-size: 16px;font-weight: 600;color: #2d3640;">{{ order.title }}</td>
                <td class="qty"><strong>×</strong>{{ order.quantity }}</td>
                <td class="date" style=" ">{{ order.duration }} gün</td>
                <td class="amount">{{ order.price }} TL</td>
              </tr>
              <tr v-else>
                <td class="truncated" style="font-size: 16px;font-weight: 600;color: #2d3640;">
                  {{ order.package.title }}
                  <div style="height: 5px;"></div>
                  <span v-if="order.package.type && order.package.type === 'premium'" style="font-size: 14px;color: rgb(139, 149, 161);">Pro Paket: </span>
                  <span v-else-if="order.package.type && order.package.type === 'standard'">Standart Paket: </span>
                  <span v-else>Temel Paket: </span>
                  <span style="font-size: 14px;color: rgb(139, 149, 161);line-height: 19px;font-weight: 400;">{{order.package.description}}</span>

                  <p v-if="order.package.title" class="detail-open-button" @click="packageDetailOpened = !packageDetailOpened">Paket içeriği <span> {{packageDetailOpened? '&#x25B2' : '&#x25BC'}}</span></p>

                  <div class="package-detail" v-if="packageDetailOpened">
                    <div class="scope-body">
                      <div v-if="order.package && order.package.revisions" class="content-scope">
                        <img class="content-img" src="https://gcdn.bionluk.com/site/icon/green_tick.svg"/>
                        <p class="content-title">
                          <span>{{order.package.revisions}}</span>
                          Revizyon
                        </p>
                      </div>
                      <div class="content-scope" v-for="(option, index) in order.package_items">
                        <img class="content-img" src="https://gcdn.bionluk.com/site/icon/green_tick.svg"/>
                        <p class="content-title">
                          <span v-if="option.value !== true">{{option.value}}</span>
                          {{option.title}}</p>

                      </div>
                    </div>
                  </div>

                </td>
                <td class="qty"><strong>×</strong>{{ order.quantity }}</td>
                <td class="date" style=" ">{{ order.package.duration }} gün</td>
                <td class="amount">{{ order.package.price }} TL</td>
              </tr>

              <tr v-for="extra in order.order_extras">
                <td class="truncated"  style="font-size: 16px;font-weight: 600;color: #2d3640;">{{ extra.title }}
                  <br><p style="font-size: 14px;color: rgb(139, 149, 161);line-height: 19px;font-weight: 400;">{{extra.description}}</p>
                </td>
                <td class="qty"><strong>×</strong>{{ extra.quantity }}</td>
                <td class="date" style=" ">{{ extra.duration }} gün</td>
                <td class="amount">{{ extra.price }} TL</td>
              </tr>

              <tr class="total">
                <th></th>
                <th></th>
                <th>{{ order.duration }} gün</th>
                <th>{{ order.total }} TL</th>
              </tr>
              </tbody>
            </table>

            <div class="orderDetail">

              <div v-if="requirements">


                <p style="text-align: left;    font-size: 16px;
    font-weight: 600;
    color: rgb(45, 54, 64);">Sipariş Başlangıcı İçin Freelancer'ın İstediği Bilgiler</p>
                <div style="color: #2d3640; text-align: left;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px; margin-top:12px">
                  {{requirements}}
                </div>

                <div class="line"></div>
              </div>

              <div class="orderActivities">
                <div v-for="activity in orderActivities">

                  <div class="hiddendiv"></div>
                  <p style="text-align: center; color: #bfc8d2; ">{{activity.created_at | customDateFormat }}</p>
                  <div class="msg" style="position: relative; text-align: left;">
                    <img style=" position: absolute; text-align: left; height: 24px; width: 24px; border-radius: 50%; margin-top: 5px;" :src="activity.user.avatar_url" class="avatar-image">
                    <div style="text-align: left; margin-left: 30px;" :class="'chatbubble ' + activity.chatbubbleClass">
                      <span v-html="activity.message"></span>
                      <div v-for="upload in activity.uploads"  style="font-size: 13px; margin-top: 3px; margin-bottom: 20px; text-align: left; margin-top: 10px;">
                        <img style="height: 13px;" src="https://gcdn.bionluk.com/site/icon/icon_attach_masmavi.svg"/>
                        <a target="_blank" :href="upload.url"  style="color: rgb(86, 165, 255);">{{upload.filename}}</a>
                      </div>

                    </div>
                    <div style="clear: both; height: 10px;"></div>
                  </div>

                </div>
              </div>

              <section  v-if="commentBox.showCommentBox" class="commentBox" style="">
                <p style="padding-top: 10px; text-align: left;font-size: 22px;color: #333333;line-height: 18px;">Yorumlar</p>

                <div v-for="comment in commentBox.comments" style="display: flex; min-height:60px;margin-top:20px;text-align: left; border: 1px solid #e1e1e1; border-radius: 4px; padding: 12px 24px; ">
                  <div style="flex: 1;height: 48px; text-align: center;">
                    <img :src="comment.user.avatar_url" style="text-align: left; height: 48px; width: 48px; border-radius: 50%; margin-top: 5px;"  class="avatar-image">
                  </div>
                  <div style="flex: 444; margin-left: 16px; text-align: left; ">
                    <p style="font-size: 16px; font-weight: 500; color: #2d3640;">
                      <span>{{comment.user.username}}</span>
                      <span style="color: #bfc8d2; font-size: 14px; padding-left: 12px; float: right">
                        {{ comment.created_at | customDateFormat('dd MMM yyyy') }}
                      </span>
                    </p>
                    <p style="font-size: 14px; padding-top: 4px; line-height: 1.2;">
                      {{comment.comment}}
                    </p>
                  </div>
                </div>


              </section>

              <section v-if="greenBox.showGreenBox" class="greenBox">
                <div class="numberCircle" style="border:6px solid #dde3eb;width: 100px; height: 100px; margin: 0 auto; text-align: center">
                <span style="line-height: 60px;width: 100px; height: 100px; padding-top: 20px;">
                  <img style="width: 60px; height: 60px;" :src="greenBox.icon">
                </span>
                </div>
                <p class="greenBoxTitle"> {{ greenBox.title }}</p>
                <p class="greenBoxDescription">{{ greenBox.description }}</p>

                <p v-if="gig_s === '1'">
                  <button @click="contactSeller(receivedUser.username)" class="contact super-button-light-small" style="margin-top: 20px;">{{receivedUser.titleText}}{{ receivedUser.titleText ===
                'Freelancer' ? `'a mesaj at` : `'ya mesaj at` }}</button>
                </p>

                <div v-if="gig_s === '1' && receivedUser.titleText === 'Alıcı' && status == '1' && !startedOrderForBuyer" style="margin-top: 10px; margin-bottom: 10px;">
                  <template v-if="!processing">
                    <p>veya</p>
                    <p @click="startOrderForBuyer()" class="startOrderForBuyer">Alıcı İçin İşlemi Başlat</p>
                  </template>

                  <p v-if="processing" class="startOrderForBuyer">yapılıyor...</p>
                </div>
              </section>

              <div class="hiddendiv"></div>

              <div v-if="actionDiv" class="actionDiv">

                <div v-if="actionDiv === 'actionWait'" class="actionWait" style="margin: 0 auto; "></div>

                <div v-if="actionDiv === 'actionStart'" class="actionStart" style="  margin: 0 auto; ">
                  <textarea v-model="message" style="width: auto; height: 150px;"></textarea>
                  <div style="clear: both"></div>
                  <div v-for="file in files" style="font-size: 13px; margin-top: 3px; margin-bottom: 20px; text-align: left; margin-top: 10px;">
                    <a target="_blank" :href="file.url"  style="color: rgb(86, 165, 255);">{{ file.filename }}</a>
                    <img @click="deleteFile(file.externalID)" src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" style=" cursor: pointer; opacity: .9; padding-left:12px;height: 14px; margin-top: 3px; position: absolute; display: inline-block;">
                  </div>

                  <div v-if="uploadResponseStatus === 'started'" style="font-size: 12px"><span style=" font-weight: 600">Uploading...</span><span style=" display:inline-block;font-size: 12px; width: 50px;">% {{ $store.state.percentCompleted.order[orderID] }}</span> <a style="padding-left: 5px;"  @click="cancelUploadRequest(upload.stamp)">İptal</a> </div>
                  <progress v-if="uploadResponseStatus === 'started'" :value='percentCompleted.order[stamp]' max="100"></progress>

                  <div style="">
                    <div style="float: left">
                      <label style="margin-top: 10px;"  for="order_upload_actionStart" class="uploadfile_text">
                        <img style="width: 16px;" src="https://gcdn.bionluk.com/site/icon/ic_attachment.svg"/>
                        Dosya Ekle
                      </label>
                      <input id="order_upload_actionStart" style="display:none;" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.ORDER)">
                    </div>
                    <button :disabled="disableToStartOrder" @click="startOrder()" class="super-button" style=" margin-top:10px;width: 100px; float: right;">
                      Siparişi Başlat
                    </button>
                    <div style="clear: both"></div>
                  </div>
                </div>

                <div v-if="actionDiv === 'actionDeliver'" class="actionDeliver" style=" margin: 0 auto;">
                  <textarea v-model="message" style="width: 100%; height: 150px;"></textarea>
                  <div style="clear: both"></div>
                  <div v-for="file in files" style="font-size: 13px; margin-bottom: 20px; text-align: left; margin-top: 10px;">
                    <a target="_blank" :href="file.url"  style="color: rgb(86, 165, 255);">{{ file.filename }}</a>
                    <img @click="deleteFile(file.externalID)" src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" style=" cursor: pointer; opacity: .9; padding-left:12px;height: 14px; margin-top: 3px; position: absolute; display: inline-block;">
                  </div>

                  <progress v-if="uploadResponseStatus === 'started'" :value='percentCompleted.order[stamp]' max="100"></progress>
                  <div style="">
                    <div style="float: left">
                      <label style="margin-top: 10px;"  for="order_upload" class="uploadfile_text">
                        <img style="width: 16px;" src="https://gcdn.bionluk.com/site/icon/ic_attachment.svg"/>
                        Dosya Ekle
                      </label>
                      <input id="order_upload" style="display:none;" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.ORDER)">
                    </div>

                    <div v-if="!coverFile.externalID" style="display:none;clear:both;float: left; width: 100%; margin-top: 10px;">
                      <label style="margin-top: 10px;"  for="order_cover_upload" class="uploadfile_text">
                        <img style="width: 16px;" src="https://gcdn.bionluk.com/site/icon/ic_attachment.svg"/>
                        Bu çalışmadan gerçek örnek oluştur.
                      </label>
                      <div style="border-top: 1px solid #e1e1e1; width: 250px;"></div>
                      <input id="order_cover_upload" style="display:none;" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.ORDER, true)">
                    </div>


                    <div v-if="coverFile.externalID" style="display:none;font-size: 13px; text-align: left; clear: both; float: left; width: 100%; margin-top: 10px">
                      <div style="border-top: 1px solid #e1e1e1; width: 250px; padding-bottom: 10px;"></div>
                      <a target="_blank" :href="coverFile.url"  style="color: rgb(86, 165, 255);">{{ coverFile.filename }}</a>
                      <img @click="deleteFile(coverFile.externalID, Constants.UPLOAD_TYPES.ORDER, true)" src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" style=" cursor: pointer; opacity: .9; padding-left:12px;height: 14px; margin-top: 3px; position: absolute; display: inline-block;">
                    </div>
                    <p style="display:none;font-size: 12px; color: #8b95a1; float: left; width: 250px; text-align: left; padding-top: 10px;">

                      Bu alana sadece <span style="color: #2d3640;">video, ses veya resim</span> dosyası ekleyebilirsin.
                      <br>
                      <br>
                      Alıcı onay verdiği takdirde bu içerik profilinde ve ilgili iş ilanında gerçek örnek olarak gözükecek.
                    </p>


                    <!-- alttaki butona @click="deliverOrder()" diye bi method lazım /orders/deliver/ a cıkıcak bu method detayl -->
                    <button @click="deliverOrder()" :disabled="disableToStartOrder" class="super-button" style=" margin-top:30px;width: 100px; float: right;">
                      Siparişi Tamamla
                    </button>
                    <div style="clear: both"></div>
                  </div>
                </div>

                <div v-if="actionDiv === 'actionApprove'" class="actionApprove" style="margin: 0 auto;">
                  <button @click="approve()" class="super-button" style=" margin-top:10px;width: 100px;">
                    Onayla ({{total}} TL)
                  </button>

                  <p style="font-size: 14px; margin-top: 15px; margin-bottom: 2px;">veya</p>

                  <!-- alttaki class basıldığında showRevision divi görülür olacak -->
                  <p @click="showRevision = true" class="hoverunderline" style="cursor: pointer; font-size: 14px; color: #2d3640; margin-bottom: 10px;">Revizyon Talep Et</p>

                  <div v-if="showRevision" class="showRevision">
                    <textarea v-model="message" style="width: 100%; height: 150px;"></textarea>
                    <div style="clear: both"></div>
                    <div v-for="file in files" style="font-size: 13px; margin-bottom: 20px; text-align: left; margin-top: 10px;">
                      <a target="_blank" :href="file.url"  style="color: rgb(86, 165, 255);">{{ file.filename }}</a>
                      <img @click="deleteFile(file.externalID)" src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" style=" cursor: pointer; opacity: .9; padding-left:12px;height: 14px; margin-top: 3px; position: absolute; display: inline-block;">
                    </div>

                    <progress v-if="uploadResponseStatus === 'started'" :value='percentCompleted.order[stamp]' max="100"></progress>
                    <div style="">
                      <div style="float: left">
                        <label style="margin-top: 10px;"  for="order_upload_revision" class="uploadfile_text">
                          <img style="width: 16px;" src="https://gcdn.bionluk.com/site/icon/ic_attachment.svg"/>
                          Dosya Ekle
                        </label>
                        <input id="order_upload_revision" style="display:none;" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.ORDER)">
                      </div>

                      <!-- alttaki butona @click="revisionOrder()" diye bi method lazım /orders/revision/ a cıkıcak bu method detayl -->
                      <button @click="revisionOrder()" :disabled="disableRevision" class="super-button" style=" margin-top:10px;width: 100px; float: right;">
                        Revizyon Talep Et
                      </button>
                      <div style="clear: both"></div>
                    </div>
                  </div>

                </div>

                <div v-if="actionDiv === 'actionCommentBuyer'" class="actionCommentBuyer" style="margin: 0 auto;">
                  <div class="hiddendiv"></div>

                  <div v-if="actionCommentBuyer === 'first'" class="actionCommentBuyerFirstStep" style="text-align: left;display: flex;flex-direction: column">

                    <h4 style="text-align: left;">Aldığın Hizmeti Değerlendir</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Yorumun freelancer'ın profilinde herkese açık bir şekilde yayınlanacaktır..</p>
                    <!-- post comment -->
                    <textarea v-model="comment" maxlength="500" style="width: auto; height: 100px;" placeholder="Bu freelancer'dan almış olduğun hizmet ile ilgili düşüncelerin."></textarea>

                    <!-- alttaki input show_real_job'ı belirtiyor. Checkbıx tikli ise 1, değilse 0 olarak gönderielcek show_real_job -->
                    <div style="margin-top: 5px;">
                      <input v-model="showRealJob" name="show_real_job" id="show_real_job" type="checkbox" checked style="display: inline; cursor: pointer; vertical-align: middle;">
                      <label for="show_real_job" style="cursor: pointer; display: inline; padding-left: 5px; top: 4px;">
                        <span style="font-size: 12px;">Freelancer'ın bu işi portfolyosuna eklemesine izin veriyorum</span>
                      </label>
                      <div style="clear: both;"></div>
                    </div>

                    <div class="hiddendiv"></div>

                    <h4>İletişim</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Freelancer'ın siparişin süresince kurmuş olduğu iletişim nasıldı?</p>
                    <!-- post communication -->
                    <star-rating v-model="communicationStar" :star-size="24" :increment="0.5" :fixed-points="2"></star-rating>

                    <div class="hiddendiv"></div>

                    <h4>Hizmet</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Siparişin anlaştığınız şekilde teslim edildi mi?</p>
                    <star-rating v-model="serviceAsDescribedStar" :star-size="24" :increment="0.5" :fixed-points="2"></star-rating>

                    <div class="hiddendiv"></div>

                    <h4>Zamanlama</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Siparişi zamanında teslim aldın mı?</p>
                    <!--  post delivery_time -->
                    <star-rating v-model="deliveryTimeStar" :star-size="24" :increment="0.5" :fixed-points="2"></star-rating>


                    <div class="hiddendiv"></div>
                    <div class="hiddendiv20"></div>

                    <h4>Bu freelancer'ı önerir misin?</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Cevabın senin profilinde ya da ilgili freelancer'ın profilinde yayınlanmayacaktır.</p>

                    <div class="hiddendiv20"></div>

                    <!-- post would_recommend 1 veya 0 -->
                    <div>
                      <div @click="switchNoImageSources(false)" class="recommend_no" style="display: inline-block;  font-weight: 600; line-height: 40px; cursor: pointer;">
                      <img class="recommend_no_image" style="position: absolute; height: 30px; opacity: .8 " :src="noImageSrc"/>
                        <div style="margin-left: 40px; margin-top: -12px;font-size: 14px; ">Hayır</div>
                      </div>

                      <div @click="switchNoImageSources(true)" class="recommend_yes" style="display: inline-block; font-weight: 600; padding-left: 14px; cursor: pointer;">
                      <img class="recommend_yes_image" style="position: absolute; height: 30px; opacity: .8 " :src="yesImageSrc"/>
                        <div style="margin-left: 40px; margin-top: 6px; font-size: 14px; ">Evet!</div>
                      </div>
                    </div>

                    <div class="hiddendiv"></div>
                    <!-- first stepi gecme butonu -->
                    <button :disabled="disabledRateButton" @click="rateSeller()" class="super-button-green" style=" margin-top:10px;width: 150px; min-width: 150px; height: 40px;">
                      Yorumu Gönder
                    </button>
                    <div style="clear: both"></div>
                    <div class="hiddendiv"></div>

                  </div>

                  <div v-if="actionCommentBuyer === 'second'" class="actionCommentBuyerSecondStep" style="text-align: left">
                    <h4 style="text-align: left; margin-bottom: 20px;">{{receivedUser.username}} ile çalışma deneyimini bizimle paylaşmak ister misin?</h4>
                    <!-- post special_note 1 veya 0 -->
                    <textarea v-model="specialNote" maxlength="500" style="width: 70%; height: 100px;" placeholder="Yorumun freelancer ile paylaşılmayacaktır."></textarea>
                    <div class="hiddendiv10"></div>

                    <!-- servise cıkılacak button -->
                    <button :disabled="disabledRateButton" @click="rateSeller()" class="super-button" style=" margin-top:10px;width: 100px; min-width: 100px; height: 36px;">
                      GÖNDER
                    </button>
                    <div style="clear: both"></div>
                    <div class="hiddendiv"></div>
                  </div>

                  <div v-if="actionCommentBuyer === 'thanks'" class="actionCommentBuyerThanksStep" style="text-align: left">
                    <h4 style="text-align: left; margin-bottom: 20px;">Yorumun için teşekkür ederiz. Birçok kullanıcı için faydalı olacak!</h4>
                    <router-link to="/panel/siparisler">
                      <button class="editButton" style=" border-radius: 2px; margin-top:10px;width: 150px; min-width: 150px; height: 36px;">
                        Siparişlere Geri Gön
                      </button>
                    </router-link>
                  </div>
                </div>

                <div v-if="actionDiv === 'actionCommentSeller'" class="actionCommentSeller" style="margin: 0 auto;">
                  <div class="hiddendiv"></div>

                  <div v-if="actionCommentSeller === 'first'" class="actionCommentSellerFirstStep" style="text-align: left">

                    <h4 style="text-align: left;">Alıcıyla yaşadığın deneyimi paylaş.</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Yorumun alıcının profilinde herkese açık bir şekilde yayınlanacaktır.</p>
                    <!-- post comment -->
                    <textarea v-model="comment" maxlength="500" style="width: 50%; height: 100px;" placeholder="Tecrübelerin..."></textarea>


                    <div class="hiddendiv"></div>

                    <h4>İletişim</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Alıcının sizinle iletişimi nasıldı?</p>
                    <!-- post communication -->
                    <star-rating v-model="communicationStar" :increment="0.5" :fixed-points="2"></star-rating>

                    <div class="hiddendiv"></div>


                    <h4>İş Özeti</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Sana verdiği iş özeti yeterli miydi? Çok fazla revizyon yaptın mı?</p>
                    <star-rating v-model="briefQualityStar" :increment="0.5" :fixed-points="2"></star-rating>

                    <div class="hiddendiv"></div>

                    <h4>Zamanlama</h4>
                    <p style="margin-top: 8px; margin-bottom: 8px; font-size: 14px;">Teslim edilen sipariş zamanında onaylandı mı?</p>
                    <!-- post delivery_time -->
                    <star-rating v-model="approvalTimeStar" :increment="0.5" :fixed-points="2"></star-rating>


                    <div class="hiddendiv"></div>
                    <div class="hiddendiv20"></div>

                    <h4>Beraber çalıştığın bu kullanıcıyı başkalarına da önerir misin?</h4>

                    <div class="hiddendiv20"></div>

                    <!-- post would_recommend 1 veya 0 -->
                    <div>
                      <div @click="switchNoImageSources(false)" class="recommend_no" style="display: inline-block;  font-weight: 600; line-height: 40px; cursor: pointer;">
                        <img class="recommend_no_image" style="position: absolute; height: 30px; opacity: .8 " :src="noImageSrc"/>
                        <div style="margin-left: 40px; margin-top: -12px;font-size: 14px; ">Hayır</div>
                      </div>

                      <div @click="switchNoImageSources(true)" class="recommend_yes" style="display: inline-block; font-weight: 600; padding-left: 14px; cursor: pointer;">
                        <img class="recommend_yes_image" style="position: absolute; height: 30px; opacity: .8 " :src="yesImageSrc"/>
                        <div style="margin-left: 40px; margin-top: 6px; font-size: 14px; ">Evet!</div>
                      </div>
                    </div>

                    <div class="hiddendiv"></div>
                    <button :disabled="disabledRateButton" @click="rateBuyer()" class="super-button-green" style=" margin-top:10px;width: 150px; min-width: 150px; height: 36px;">
                      Devam
                    </button>
                    <div style="clear: both"></div>
                    <div class="hiddendiv"></div>

                  </div>

                  <div v-if="actionCommentSeller === 'second'" class="actionCommentSellerSecondStep" style="text-align: left">
                    <h4 style="text-align: left; margin-bottom: 20px;">{{receivedUser.username}} ile çalışma deneyimini bizimle paylaşmak ister misin?</h4>
                    <textarea v-model="specialNote" maxlength="500" style="width: 70%; height: 100px;" placeholder="Yorumun alıcı ile paylaşılmayacaktır."></textarea>
                    <div class="hiddendiv10"></div>

                    <button :disabled="disabledRateButton" @click="rateBuyer()" class="super-button" style=" margin-top:10px;width: 100px; min-width: 100px; height: 36px;">
                      GÖNDER
                    </button>
                    <div style="clear: both"></div>
                    <div class="hiddendiv"></div>
                  </div>

                  <div v-if="actionCommentSeller === 'thanks'" class="actionCommentSellerThanksStep" style="text-align: left">
                    <h4 style="text-align: left; margin-bottom: 20px;">Yorumun için teşekkür ederiz. Birçok kullanıcı için faydalı olacak!</h4>
                    <router-link to="/panel/siparisler">
                      <button class="editButton" style=" border-radius: 2px; margin-top:10px;width: 150px; min-width: 150px; height: 36px;">
                        Siparişlere Geri Gön
                      </button>
                    </router-link>
                  </div>
                </div>

              </div>
            </div>

          </div>


          <div v-if="resolutionSection" class="resolutionCenter" >

            <app-redirect/>
            <template v-if="1===2">
              <h4 style="padding: 10px 0">Sipariş Çözüm Merkezi</h4>
              <div class="orderActivities">
                <div class="alwaysExist">
                  <p style="text-align: center; color: #bfc8d2; "></p>
                  <div class="msg" style="position: relative; text-align: left;">
                    <img style=" position: absolute; text-align: left; height: 24px; width: 24px; margin-top: 5px;" src="https://gcdn.bionluk.com/site/icon/faviconsv2/apple-icon-144x144.png" class="avatar-image">
                    <div style="text-align: left; margin-left: 30px;" class="chatbubble right">
                    <span>Siparişinle ilgili sorun yaşadığını düşünüyorsan bu alanı kullanabilirsin. Bu alanda
                      yazılan mesajlar freelancer ve çözüm merkezi uzmanlarımız tarafından görüntülenebilmektedir.</span>
                    </div>
                    <div style="clear: both; height: 10px;"></div>
                  </div>
                </div>

                <div v-for="ticket in ticketMessages">
                  <div class="hiddendiv"></div>
                  <p style="text-align: center; color: #bfc8d2; ">{{ ticket.created_at | customDateFormat }}</p>
                  <p style="text-align: center; color: #bfc8d2; "></p>
                  <div class="msg" style="position: relative; text-align: left;">
                    <img style=" position: absolute; text-align: left; height: 24px; width: 24px; border-radius: 50%; margin-top: 5px;" :src="ticket.sender === 'admin' ? 'https://gcdn.bionluk.com/site/icon/faviconsv2/apple-icon-144x144.png' : ticket.ticket_user.avatar_url" class="avatar-image">
                    <div style="text-align: left; margin-left: 30px;" :class="ticket.sender === 'user' ? 'chatbubble left' : 'chatbubble right'">
                      <span v-html="ticket.message"></span>
                      <div v-for="supportAttachment in ticket.uploads" style="font-size: 13px; margin-bottom: 20px; text-align: left; margin-top: 10px;">
                        <a target="_blank" :href="supportAttachment.url"  style="color: rgb(86, 165, 255);">{{ supportAttachment.filename }}</a>
                      </div>
                    </div>
                    <div style="clear: both; height: 10px;"></div>
                  </div>
                </div>

              </div>

              <div style="display: flex;flex-direction: column">
                <div class="hiddendiv10"></div>
                <textarea v-model="ticketMessage" placeholder="Mesajını yaz..." style="width: auto; height: 150px;"></textarea>
                <div style="clear: both"></div>
                <div v-for="file in supportFiles" style="font-size: 13px; margin-bottom: 20px; text-align: left; margin-top: 10px;">
                  <a target="_blank" :href="file.url"  style="color: rgb(86, 165, 255);">{{ file.filename }}</a>
                  <img @click="deleteFile(file.externalID, Constants.UPLOAD_TYPES.TICKET)" src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" style=" cursor: pointer; opacity: .9; padding-left:12px;height: 14px; margin-top: 3px; position: absolute; display: inline-block;">
                </div>

                <div style="text-align: left;" v-if="$store.state.percentCompleted.order[stamp] > 0.1 && uploadResponseStatus === 'started'">
                  <div style="font-size: 12px"><span style=" font-weight: 600">Uploading...</span><span style=" display:inline-block;font-size: 12px; width: 50px;">% {{ percentCompleted.order[stamp] }}</span> <a style="padding-left: 5px;"  @click="cancelUploadRequest(stamp)">İptal</a> </div>
                  <progress :value='percentCompleted.order[stamp]' max="100"></progress>
                </div>
                <div>
                  <div style="float: left">
                    <label style="margin-top: 10px;"  for="order_support_upload" class="uploadfile_text">
                      <img style="width: 16px;" src="https://gcdn.bionluk.com/site/icon/ic_attachment.svg"/>
                      Dosya Ekle
                    </label>
                    <input id="order_support_upload" style="display:none;" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.TICKET)">
                  </div>

                </div>
              </div>

              <div style="display: flex;justify-content: flex-end">
                <button :disabled="ticketMessage && ticketMessage.length < 1" @click="createOrderTicket()" class="super-button" style=" margin-top:10px;width: 100px">
                  Gönder
                </button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import appRedirect from "../../../../components/appRedirect";
  export default {
    name: "src-pages-body-workstation-orders-detail-v1_mobile",
    components:{appRedirect},
    data() {
      return {

      	gig_s:'1',
        packageDetailOpened: false,
        pageLoaded: false,
        resolutionLoader: false,
        order: null,
        imageURL: null,
        slugURL: null,
        title: null,
        status: null,
        receivedUser: null,
        payedAt: null,
        resolutionSection: null,
        orderActivities: [],
        greenBox: {},
        commentBox: {},
        actionDiv: null,
        files: [],
        supportFiles: [],
        orderID: null,
        uploadResponseStatus: null,
        message: null,
        ticketMessages: [],
        stamp: null,
        ticketMessage: '',
        startedOrderForBuyer: false,
        coverFile: {},
        showRevision: null,
        disableRevision: false,
        processing: false,
        deliveryTimeStar: 0,
        serviceAsDescribedStar: 0,
        communicationStar: 0,
        recommendedKey: null,
        noImageSrc: 'https://gcdn.bionluk.com/site/icon/ic_thumb_down.svg',
        yesImageSrc: 'https://gcdn.bionluk.com/site/icon/ic_thumb_up.svg',
        recommended: null,
        actionCommentBuyer: 'first',
        showRealJob: 1,
        specialNote: null,
        comment: null,
        disabledRateButton: null,
        actionCommentSeller: 'first',
        briefQualityStar: 0,
        approvalTimeStar: 0,
        requirements:null,
        total:0,
      }
    },
    methods: {
      contactSeller(username) {
        this.$router.push(`/chat/${username}`);
      },
      switchNoImageSources(recommended) {
        // recommendKey covers specific recommended value, if recommended value is changed, it will starts over again
        if (this.recommended !== recommended) {
          this.recommendedKey = null;
          this.recommended = recommended;
        } else {
          this.recommended = null;
        }

        this.recommendedKey = !this.recommendedKey;
        if (recommended) {

          this.noImageSrc = 'https://gcdn.bionluk.com/site/icon/ic_thumb_down.svg';
          this.yesImageSrc = this.recommendedKey ? 'https://gcdn.bionluk.com/site/icon/ic_thumb_up_mavi.svg' : 'https://gcdn.bionluk.com/site/icon/ic_thumb_up.svg';
        } else {

          this.noImageSrc = this.recommendedKey ?  'https://gcdn.bionluk.com/site/icon/ic_thumb_down_mavi.svg' : 'https://gcdn.bionluk.com/site/icon/ic_thumb_down.svg';
          this.yesImageSrc = 'https://gcdn.bionluk.com/site/icon/ic_thumb_up.svg';
        }
      },

      switchTab(type) {
        if (type === "resolution") {
          if (!this.resolutionSection) {
            this.resolutionSection = true;
            this.cancelUploadRequest(this.stamp);
          }

        } else {
          if (this.resolutionSection) {
            this.resolutionSection = false;
            this.cancelUploadRequest(this.stamp);
            this.ticketMessage = [];
            this.message = null;
            this.stamp = null;
          }
        }
      },

      startOrder() {
        let externalList = [];
        this.files.forEach(function (file) {
          externalList.push(file.externalID);
        });
        this.api.orders.startOrder(this.message, externalList, this.orderID)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.actionDiv = null;
              this.orderActivities.push(result.data.order_activity);
              this.greenBox = result.data.greenBox;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      startOrderForBuyer() {
        this.processing = true;
        this.api.orders.startOrderForBuyer(this.orderID, this.$Progress)
          .then(({data}) => {
            this.processing = false;
            let result = data;

            if (result.success) {
              this.startedOrderForBuyer = true;
              this.orderActivities.push(result.data.order_activity);
              this.greenBox = result.data.greenBox;
              this.actionDiv = "actionDeliver";
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      deliverOrder() {
        let alert = "Bu siparişine bir kapak fotoğrafı eklemedin. Bu sebeple bu proje portfolyoların arasına gerçek iş örneği olarak eklenmeyecektir.";
        if (this.coverFile.externalID || confirm(alert) === true ) {
          let IDs = [];
          this.files.forEach(function (file) {
            IDs.push(file.externalID);
          });
          this.api.orders.deliverOrder(this.message, IDs, this.orderID, this.coverFile.externalID, this.$Progress)
            .then(({data}) => {
              let result = data;

              if (result.success) {
                this.orderActivities.push(result.data.order_activity);
                this.greenBox = result.data.greenBox;
                this.message = null;
                this.actionDiv = null;
                this.files = [];
                this.$toasted.global.infoToast({description: result.message});
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }
      },

      approve() {
        let alert = "Siparişi onaylamak üzeresin. Sipariş tutarı freelancer'a aktarılacaktır ve bu işlemin geri dönüşü mümkün değildir. Devam etmek istiyor musun?";

        if (confirm(alert)) {
          this.api.orders.approveOrder(this.orderID)
            .then(({data}) => {
              let result = data;

              if (result.success) {
                this.orderActivities.push(result.data.order_activity);
                this.greenBox = result.data.greenBox;
                if(result.data.show_comment_div){
                  this.actionDiv = "actionCommentBuyer";
                } else {
                  this.actionDiv = 'actionWait';
                }
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});

            })
        }
      },

      revisionOrder() {
        this.disableRevision = true;
        let IDs = [];
        this.files.forEach(function (file) {
          IDs.push(file.externalID);
        });
        this.api.orders.revisionOrder(this.message, IDs, this.orderID, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.disableRevision = true;

            if (result.success) {
              this.orderActivities.push(result.data.order_activity);
              this.greenBox = result.data.greenBox;
              this.message = null;
              this.actionDiv = null;
              this.files = [];
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      arrangeRateSellerBody() {
        let wouldRecommend;
        let showRealJob = this.showRealJob ? 1 : 0;

        if (this.recommended === null) {
          wouldRecommend = 0;
        }
        if (this.recommended) {
          wouldRecommend = 1;
        }
        if (this.recommended === false) {
          wouldRecommend = -1;
        }


        return {
          order_id: this.orderID,
          would_recommend: wouldRecommend,
          service_as_described: this.serviceAsDescribedStar,
          delivery_time: this.deliveryTimeStar,
          communication: this.communicationStar,
          comment: this.comment,
          special_note: this.specialNote,
          show_real_job: showRealJob
        }
      },

      rateSeller() {
        this.disabledRateButton = true;

        this.api.orders.rateSeller(this.arrangeRateSellerBody(), this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.actionCommentBuyer = 'thanks';
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.disabledRateButton = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.disabledRateButton = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      arrangeRateBuyerBody() {
        let wouldRecommend;
        let showRealJob = this.showRealJob ? 1 : 0;

        if (this.recommended === null) {
          wouldRecommend = 0;
        }
        if (this.recommended) {
          wouldRecommend = 1;
        }
        if (this.recommended === false) {
          wouldRecommend = -1;
        }


        return {
          order_id: this.orderID,
          would_recommend: wouldRecommend,
          brief_quality: this.briefQualityStar,
          approval_time: this.approvalTimeStar,
          communication: this.communicationStar,
          comment: this.comment,
          special_note: this.specialNote
        }
      },

      rateBuyer() {
        this.disabledRateButton = true;

        this.api.orders.rateBuyer(this.arrangeRateBuyerBody(), this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.actionCommentSeller = 'thanks';
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.disabledRateButton = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.disabledRateButton = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      onFileChange(e, type, isCover) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        this.uploadResponseStatus = "started";
        this.stamp = (type === this.Constants.UPLOAD_TYPES.TICKET) ? +new Date() : this.orderID;


        this.uploadFileToGoogle(files[0], type, this.stamp)
          .then(result => {

            let file = {
              url: this.Constants.CDN_UPLOADS_G + result.uploadName,
              externalID: result.externalID,
              filename: result.filename
            };

            if (isCover) {
              this.coverFile = file
            } else {
              if (type === this.Constants.UPLOAD_TYPES.TICKET) {
                this.supportFiles.push(file);
              } else {
                this.files.push(file);
              }
            }

            this.uploadResponseStatus = null;
            e.target.value = '';
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadResponseStatus = null;
            e.target.value = '';
          });
      },

      deleteFile(uploadID, type, isCover) {
        this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
          .then(({data}) => {
            let result = data;

            if (result.success) {

              if (isCover) {
                this.coverFile = {};
              } else {
                if (type === this.Constants.UPLOAD_TYPES.TICKET) {
                  this.supportFiles = this.supportFiles.filter(function (file) {
                    return file.externalID !== uploadID
                  });
                } else {
                  this.files = this.files.filter(function (file) {
                    return file.externalID !== uploadID
                  });
                }
              }

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      createOrderTicket() {
        let IDs = [];
        this.supportFiles.forEach(function (file) {
          IDs.push(file.externalID);
        });
        this.api.general.createOrderTicket(this.ticketMessage, this.orderID, IDs, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.ticketMessages.push(result.data.ticket_message);
              this.ticketMessage = '';
              this.supportFiles = [];
              this.stamp = null;

              this.$toasted.global.errorToast({description: result.message});

            } else {
              this.$toasted.global.infoToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      getOrder(ID) {
        this.api.orders.getOrder(ID)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.pageLoaded = true;
              this.title = result.data.title;
              this.imageURL = result.data.image_url;
              this.slugURL = result.data.gig_slug_url;
              this.status = result.data.status;
              this.receivedUser = result.data.user;
              this.payedAt = result.data.payed_at;
              this.order = result.data.order_details;
              this.orderID = result.data.order_id;
              this.orderActivities = result.data.orderActivities;
              this.greenBox = result.data.greenBox;
              this.commentBox = result.data.commentBox;
              this.actionDiv = result.data.actionDiv;
              this.ticketMessages = result.data.ticket_messages;
              this.gig_s = result.data.gig_s;
              this.total = result.data.total - result.data.total_refund;

              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              this.requirements = result.data.requirements;

              this.Helper.trackEvents.pageView(this.Helper.orderDetailPageProps(result.data));
              if (result.data.forceContactModal) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ORDER_CONTACT_INFO});
              }
            } else {
              this.$router.push(result.data.redirect_url);
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    watch: {
      "$store.state.routerParams": function (newValue, oldValue) {
        let path = this.$store.state.route.fullPath.split("/")[1];

        if (newValue && newValue.length && path === "orders") {
          this.pageLoaded = false;
          this.getOrder(newValue[0]);
        }
      },
      'route.query.tab': function(newVal, oldVal) {
        if(newVal === 'support') {
          this.resolutionSection = true;
        } else if(newVal === 'detail') {
          this.resolutionSection = false;
        }
      }
    },

    computed: {
      disableToStartOrder() {
        return false;
      }
    },

    created() {
      if(this.route.query.tab === 'support') {
        this.resolutionSection = true;
      } else if(this.route.query.tab === 'detail') {
        this.resolutionSection = false;
      }
      this.getOrder(this.$store.state.routerParams[0]);
    }
	}

</script>

<style scoped lang="scss">
  .ui.table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: none;
    border-radius: .28571429rem;
    text-align: left;
    color: rgba(0,0,0,.87);
    border-collapse: separate;
  }

  .ui.table, table {
    border-spacing: 0;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }

  .ui.padded.table td, .ui.padded.table th {
    padding: 0.4em;
  }


  .order_detail_title{
    color: #2d3640;

  }

  .order_detail_title{
    text-decoration: none;
  }

  .order_detail_username{
    color: #2d3640;
  }

  .order_detail_username{
    text-decoration: none;
  }


  .order_header_tab{
    width: 80px; height: 60px;padding: 7px 0;
    cursor: pointer;
  }

  .order_header_tab.active{
    border-bottom:3px solid #2d3640; width: 80px; height: 60px;padding: 7px 0;
    cursor: pointer;
  }

  .numberCircle {

    border-radius: 50%;
    width: 36px;
    font-size: 28px;
    border: 2px solid #2d3640;

  }
  .numberCircle span {

    text-align: center;
    line-height: 30px;
    display: block;
    color: #5e6b79;
    height: 36px;
  }

  .greenBox{
    margin: 0 auto; padding:12px 30px; text-align:center; font-size:14px; border-radius:15px; background-color: #D7F8F5;margin-top: 30px;
  }


  .blueBox{
    margin: 0 auto;  width:550px;padding:12px 30px; text-align:center; font-size:14px; border-radius:15px; background-color: #2d3640;margin-top: 30px;
  }


  .chattarih {
    text-align:left; border-bottom: solid 1px #dde3eb; line-height:0.1em;
    margin-top: 40px; margin-bottom: 20px;
  }





  .chattarih span {
    background:#fff; padding:0 15px;
    font-size: 14px;

    color: #8b95a1;
  }


  .chatbubble.right{
    color: #2d3640;background-color: #D7EBFF;

  }

  .chatbubble.left{color: #2d3640; background-color: rgba(245, 245, 245, 0.8); }

  .chatbubble{border-radius: 7.5px; position: relative; width: auto;  display: inline-block;
    font-size: 14px; margin-top: 5px;
    line-height: 1.29;
    padding: 8px 15px;}

  .chattime{position: relative; float: right;	font-size: 12px;
    color: #868a8a; right: -9px; top: 9px; font-weight: 300; }

  .seemore{ cursor: pointer;	font-weight: 600;text-align: center;color: #2d3640;}


  .input-placeholder {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    margin-left: 10px;
    margin-top: 14px;

    color: #a4a4a4;
    pointer-events: none;
    transition: opacity .08s linear;
    z-index: 100;
    font-size: 15px;
    line-height: 20px;

  }

  div.input{
    word-wrap: break-word;
    white-space: pre-wrap;
    padding: 0 2px 0 0;

    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 2px;
    background-color: #fefdfd;
    border: solid 1px #bfc8d2;
    resize: none;
    padding: 13px 10px;
    font-size: 16px;

    color: #2d3640;
    position: relative;
    z-index: 1;
    max-height: 150px;
  }

  div.input:focus{
    outline:none;
    border: solid 1px #2d3640;



  }

  .yaziyor{font-style: italic; font-size: 12px;}

  .seen{margin-top:10px;float: right;font-size: 12px;color: #8B95A1;}

  .attach_icon{position: absolute; cursor: pointer; z-index: 11; margin-left: 535px;  margin-top: 17px;}

  .chatsend{position: absolute; z-index: 11; margin-left: 555px; height: 36px; margin-top: 5px; border-radius: 2px; margin-bottom: 0;}


  .messageMenuItem{
    cursor: pointer;
    color: #8b95a1;
    font-weight: 500;
  }
  .messageMenuItem:hover{
    color: #2d3640;
  }

  .messageMenu{
    margin-left: 662px; z-index: 2; margin-top: 32px; position:absolute; width:80px; padding:20px;background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2); height:auto
  }

  .closeChatMenu{
    cursor: pointer;
    position: absolute; margin-left: 85px; margin-top: -10px; height: 10px;
  }

  .openChatMenu{cursor:pointer;height: 6px;}


  .uploadfile_text{
    float: left;  cursor: pointer; font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  .uploadfile_text:hover{
    text-decoration: underline;
  }



  .greenBoxTitle{
    padding-top: 10px;font-size: 18px;color: #333333;line-height: 18px;
  }


  .greenBoxDescription {

    margin-top:5px;opacity: 0.6;font-size: 14px;color: #1C1C1C;line-height: 20px;
  }



  .startOrderForBuyer{
    margin-top: 10px; margin-bottom: 10px; color: #2d3640; font-weight: 500; text-decoration: underline; cursor: pointer;
  }

  .actionStart{
    display: flex;
    flex-direction: column;
  }



  .detail-open-button {
    cursor: pointer;
    color: #26ca9a;
    font-size: 14px;
    font-weight: 500;

    span {
      font-size: 10px;
    }
  }

  .package-detail {
    margin-top: 20px;

    .title {
      font-weight: 500;
      color: #4b4f52;
      font-size: 16px;
    }

    .description {
      margin-top: 5px;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 19px;
      font-weight: 300;
      color: #4b4f52;
    }
  }


  .scope-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 120px;
    width: min-content;
  }

  .content-scope {
    display: flex;
    margin-bottom: 7px;
    width: 150px;
  }

  .content-title {
    color: #2d3640;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
  }

  .content-img {

    margin-right: 8px;
  }
</style>
